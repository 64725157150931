<template>
  <div class="form-group busca-simples-api" v-click-outside="onClickOutside" @keydown.esc="ShowBox = false">
    <input
      type="text"
      :placeholder="PlaceholderText"
      class="form-control busca"
      v-model="SearchText"
      @keyup="changeText()"
      @paste="changeText()"
      @focus="ShowBox = true"
      @click="ShowBox = true, changeText()" />
    <div class="busca-simples-api-lista" :style="{'width': Width}" v-if="ShowBox">
      <div v-if="SearchText.length < 3" class="item">
        <span>Digite para pesquisar (pelo menos 3 caracteres)</span>
      </div>
      <div v-if="ItemsProp.length == 0 || (Items.length == 0 && SearchText.length >= 3)" class="text-center py-1">
        <span>Nenhum registro para ser exibido</span>
      </div>
      <div v-if="SearchText.length >= 3 && Items.length > 0">
        <div class="item span-striped" v-for="(item, key) in Items" :key="key" @click="clickItem(item)">
          <slot name="item" :item="item">
            <span >{{item[Field]}}</span>
          </slot>            
        </div>
      </div>
      <div v-if="CreateItem && SearchText.length >= 3" class="py-3 text-center" id="divCadastro" style="cursor: pointer;">
        <span @click="registerItem()">Clique aqui para cadastrar o item <span class="font-weight-bold">{{SearchText}}</span></span>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  name: 'select2',
  props: [
    'ApiUrlProp', 'ApiDataProp', 'PlaceholderTextProp', 'WidthProp', 'FieldProp', 'ItemsProp', 'ClearAfterSelectedProp', 'SelectedItemProp', 'CreateItemProp'
  ],
  data () {
    return {
      ApiUrl: '', 
      ApiData :'', 
      PlaceholderText: '', 
      Field: '',
      Items: [],
      ShowBox: false,
      SearchText: '',
      ClearAfterSelected: false,
      CreateItem: false
    }
  },
  created () {
    this.ApiUrl = this.ApiUrlProp
    this.ApiData = this.ApiDataProp
    this.PlaceholderText = this.PlaceholderTextProp
    this.Width = this.WidthProp ? this.WidthProp : '100%'
    this.CreateItem = this.CreateItemProp ? this.CreateItemProp : false
    this.Field = this.FieldProp
    this.Items = this.ItemsProp
    this.ClearAfterSelected = this.ClearAfterSelectedProp ? this.ClearAfterSelectedProp : false
    //this.changeText()
    if(this.SelectedItemProp) {
      this.clickItem(this.SelectedItemProp)
    }
  },
  watch: {
    /*SelectedItemProp: function () {
      this.clickItem(this.SelectedItemProp)
    }*/
    // ItemsProp: function () {
    //   this.changeText()
    // }
  },
  methods: {
    onClickOutside () {
      this.ShowBox = false
    },
    changeText() {
      this.$emit('searchText', this.SearchText)
      if (this.SearchText.length >= 3) {
        // this.ShowBox = false
        //this.Items = this.ItemsProp.length > 10 ? this.ItemsProp.slice(0, 10) : this.ItemsProp
        this.ItemsSearch()
        // this.OutItem.emit(null)
      }
      if (this.SearchText.length == 0) {
        this.clickItem(null)
      }
    },
    ItemsSearch() {
      //let items = this.ItemsProp.filter( x => x[this.FieldProp].toLowerCase().includes(this.SearchText.toLowerCase()))
      let filterKey = this.SearchText.toLowerCase()
      //console.log(filterKey)
      filterKey = filterKey.replace(/[^a-zA-Z0-9]/g, '')
      let data = this.ItemsProp
      let items = []
      
      if(this.Field != '') {
        items = data.filter( (row) => {
          return Object.keys(row).some( (/*key*/) => {
            // FAZ LEITURA EM OBJETO DENTRO DO ARRAY
            /*
            if(row[key] instanceof Object) {
              if(this.Field in row[key] ) return row[key][this.Field].replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
            } else {
              return String(row[this.Field]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
            }
            */
            return String(row[this.Field]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
            //return String(row[key]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(filterKey)
          })
        })
      } else {
        items = data.filter( (row) => {
          return Object.keys(row).some( (key) => {
            // FAZ LEITURA EM OBJETO DENTRO DO ARRAY
            /*
            if(row[key] instanceof Object) {
              return Object.keys(row[key]).some((key2) => { return String(row[key2]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1 })
            } else {
              return String(row[key]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
            }
            */
            return String(row[key]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
            //return String(row[key]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().includes(filterKey)
          })
        })
      }
      /*
      items = data.filter( (row) => {
        return Object.keys(row).some( (key) => {
          return String(row[key]).replace(/[^a-zA-Z0-9]/g, '').toLowerCase().indexOf(filterKey) > -1
        })
      })
      */
      
     
      //console.log(items)
      //this.Items = items.slice(0, 20)
      this.Items = items
    },
    onSearch(searchText) {
      if (searchText === null || searchText.match(/^ *$/) !== null) {
        this.OutItem.emit(null)
        return
      }
      let data = {parametro: searchText, empresa: this.varsProd.NomeEmpresa}
      this.searchBoxService.PostListItems(this.urlApiService, data).subscribe( response => {
        this.Items = response['dados']
      })
    },
    clickItem(item) {
      this.$emit('Selected', item)
      this.SearchText = this.ClearAfterSelected || item == null ? '' : item[this.Field]
      if (item !== null) {
        this.ShowBox = false
      }
    },
    blurEvent() {
      this.ShowBox = false
    },
    registerItem () {
      this.$emit('CreatingItem', this.SearchText)
    }
  }
}
</script>

<style scoped>
#divCadastro:hover {
  background-color: #efefef;
}
.span-striped:nth-child(even) {
	background-color: #f2f2f2;
}
 .busca-simples-api {
	position: relative;
}
 .busca-simples-api .busca {
	width: 100%;
	margin-bottom: 0;
}
 .busca-simples-api .busca-simples-api-lista {
	width: 100%;
	max-height: 300px;
	position: absolute;
	z-index: 2;
	background-color: white;
	box-shadow: 0px 1px 3px 1px #989797;
	overflow: auto;
	margin-top: 2px;
}
 .busca-simples-api .busca-simples-api-lista .item {
	padding: 7px 16px;
	font-weight: bold;
	font-size: 15px;
	cursor: pointer;
}
 .busca-simples-api .busca-simples-api-lista .item:hover {
	background-color: #4fc3f7;
	color: white;
}
 .busca-simples-api .busca-simples-api-lista::-webkit-scrollbar {
	width: 10px;
}
 .busca-simples-api .busca-simples-api-lista::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #ded9d9;
	border-radius: 20px;
	margin: 2px;
	width: 10px;
}
 .busca-simples-api .busca-simples-api-lista::-webkit-scrollbar-thumb {
	background-color: #ded9d9;
	border-radius: 20px;
}
 .busca-simples-api .busca-simples-api-lista::-webkit-scrollbar-thumb:hover {
	background-color: #bfbfbf;
}
 
</style>